import React from "react";
import { Helmet } from "react-helmet";
import { Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import {
  Button,
  FlipCard,
  Hero,
  IconBox,
  SecondaryHeader,
  SectionHeader,
  Link,
} from "../components/common";

import Percentage from "../assets/images/vectors/percentage.svg";
import PercentageMike from "../assets/images/vectors/percentage-mike.svg";
import QuestionMark from "../assets/images/vectors/question-mark.svg";
import ArrowDown from "../assets/images/vectors/arrow-down.svg";
import { getStaticSEOImage } from "../helpers";

function AffiliatePage({ location }) {
  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Affiliate"
        image={getStaticSEOImage("affiliate")}
        path={location?.pathname}
      />
      <Helmet>
        <script
          async
          src="https://platform.twitter.com/widgets.js"
          charSet="utf-8"
        />
      </Helmet>
      <section className="mt-20 mb-40">
        <Hero
          subTitle="Join as Affiliate"
          title={
            <>
              Promote Vackground
              <br />
              Earn Straight 30% On Each Sale
            </>
          }
          description="Spread our products on social media, blog, forum, email newsletter or any personal platform and earn a straight 30% commission of every purchase made through your unique link"
          action={
            <div className="mt-8 relative inline-block">
              <Button hasMoustache text="Become an Affiliate" />
              <GatsbyLink className="block mt-4 text-brand-primary" to="/">
                Affiliate Login
              </GatsbyLink>
            </div>
          }
        />
      </section>
      <section className="mb-32">
        <div className="text-center mb-24">
          <SecondaryHeader
            title={
              <>
                3 Easy Steps To Make Money
                <br />
                Join. Share. Earn.
              </>
            }
          />
        </div>
        <div className="flex items-center gap-x-8">
          <IconBox icon="vg-file" title="Join Us">
            Just register a <Link to="/">Tapfilliate account</Link> (takes a
            minute only), and wait for your confirmation email
          </IconBox>
          <IconBox icon="vg-link" title="Share Link">
            Generate your affiliate link and share the products on your website,
            social media or email campaign
          </IconBox>
          <IconBox icon="vg-dollar-sign" title="Earn Money">
            Start earning money as soon as your links convert, you will be
            credited with your referral commission
          </IconBox>
        </div>
      </section>
      <section className="mb-32">
        <div className="mb-12">
          <SectionHeader
            art
            artVector={Percentage}
            title={
              <>
                Calculate Your Earnings
                <br />
                Get More Money With Less Sales
              </>
            }
            description={
              <>
                Our product price average is <strong>$39</strong>. With 30%
                commission, if you can generate <strong>50</strong>? sales per
                month, you will earn roughly...
              </>
            }
          />
        </div>
        <div className="flex items-center justify-center">Calculator</div>
      </section>
      <section className="mb-32">
        <div>
          <SectionHeader
            art
            artVector={PercentageMike}
            title={
              <>
                Satisfied Affiliate Marketers
                <br />
                Already Earning
              </>
            }
          />
        </div>
        <div className="grid grid-cols-3 gap-x-8 gap-y-4">
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
        </div>
        <div className="mt-20 flex items-center justify-center">
          <Button text="I’m Interested, Let Me Join" />
        </div>
      </section>
      <section className="mb-32">
        <div>
          <SectionHeader
            art
            artVector={QuestionMark}
            title={
              <>
                Have Questions?
                <br />
                Read The FAQs or Contact Us
              </>
            }
          />
        </div>
        <div className="px-48">
          <FlipCard title="What kind of graphic resources you make regularly?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="Do your surface patterns can be repeatable (tile-able)?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="Do your files backward Adobe software compatible?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="How to edit vector files?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="Can I use the design files in unlimited projects?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="Can I use the free files in my design project for commercial print?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="Can I use Apple Pay or Google Wallet?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="I need a product right now ASAP, but would like to pay later, what should I do?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="If I face any difficulties to work with the files, would you help?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
          <FlipCard title="I have a question to ask, how can I contact?">
            Creating a great looking design is hard, really hard. It requires
            years of experience. Even you know and have the experience, you will
            find tough times to add some extra elements which is not your type.
            We, as a
          </FlipCard>
        </div>
      </section>
      <section className="mb-24">
        <div>
          <SectionHeader
            art
            artVector={ArrowDown}
            title={
              <>
                Ready to Make Money?
                <br />
                Join & Take Your 30% on Every Sale!
              </>
            }
          />
        </div>
        <div className="flex items-center justify-center flex-col">
          <Button hasMoustache text="Become an Affiliate" />
          <GatsbyLink className="mt-2 text-center text-brand-primary" to="/">
            Affiliate Login
          </GatsbyLink>
        </div>
      </section>
    </Layout>
  );
}

AffiliatePage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default AffiliatePage;
